import { FREE_CREDITS_AMOUNT } from '@lessonPlanner/constants';

export const DEFAULT_POPUP_MODAL_DATA = {
  isOpen: false,
  header: 'Welcome to Lesson Planner PH',
  body: `We have given you ${FREE_CREDITS_AMOUNT} regular credits to get you started.`,
  okButtonText: 'Get Started',
  imagePath: '/images/welcome-confetti.svg',
  isCloseable: true,
};

export interface ResponseObjectTavily {
  query: string;
  follow_up_questions: string;
  answer: string;
  images: string[];
  results: {
    title: string;
    url: string;
    content: string;
    score: number;
    raw_content: string;
  }[];
  response_time: number;
}

// export const LINKS_AND_REF_INTRO = 'Further discussion with the help of online references are as follows: ';
export const LINKS_AND_REF_INTRO =
  'Promote further discussion with online references using the following: ';
export const LINKS_AND_REF_BIBLIO = 'References';

export interface ImageDisplayProps {
  images: string[];
}
