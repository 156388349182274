import { Dispatch, Fragment, SetStateAction } from 'react';

import { Transition, Dialog } from '@headlessui/react';
import Image from 'next/image';

import { DEFAULT_POPUP_MODAL_DATA } from '../constants/modal';

import { Button } from './Button';
import { CreditPopUpDataType } from './Navbar';

type PopUpModalType = {
  isOpen?: boolean;
  setIsOpen: Dispatch<SetStateAction<CreditPopUpDataType>>;
  header?: string;
  body?: string;
  okButtonText?: string;
  imagePath?: string;
  isCloseable?: boolean;
};

function PopUpModal({
  isOpen = DEFAULT_POPUP_MODAL_DATA.isOpen,
  setIsOpen,
  header = DEFAULT_POPUP_MODAL_DATA.header,
  body = DEFAULT_POPUP_MODAL_DATA.body,
  okButtonText = DEFAULT_POPUP_MODAL_DATA.okButtonText,
  imagePath = DEFAULT_POPUP_MODAL_DATA.imagePath,
  isCloseable = DEFAULT_POPUP_MODAL_DATA.isCloseable
}: PopUpModalType) {
  function handleCloseModal() {
    setIsOpen((prev) => ({ ...prev, isOpen: false }));
    localStorage.setItem('wasPopupClosed', new Date().toISOString());
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOutsideClick = () => {};

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50"
        onClose={handleOutsideClick}
      >
        <div className="fixed inset-0 z-20 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-5/6 max-w-full transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-4/6 lg:w-3/6 xl:w-2/6">
                <div className="px-4 pb-4 pt-5 sm:p-6">
                  <div className="mt-sm-8 mx-auto mt-3 flex h-auto sm:w-[230px]">
                    <Image
                      src={imagePath}
                      alt="success image"
                      width={230}
                      height={134}
                      style={{ width: '100%' }}
                    />
                  </div>
                  <Dialog.Title
                    as="h3"
                    className="relative mt-4 flex justify-center text-center text-2xl font-bold leading-tight text-[#212121B2]"
                  >
                    {header}
                  </Dialog.Title>
                  <div className="mt-2 mb-4 text-center text-[#212121B2] sm:mb-2">
                    {body}
                  </div>
                </div>
                <div className="flex justify-center gap-3 bg-[#F3F6FD] p-4">
                  {isCloseable && (
                    <Button
                      type="button"
                      className="inline-block w-[90%] rounded-lg border border-[#2463EB] bg-[#2463EB] py-2 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-[#2463EB] active:bg-blue-400 disabled:cursor-not-allowed disabled:bg-blue-600 disabled:text-white disabled:opacity-60 disabled:hover:border-blue-400 sm:w-2/5"
                      onClick={handleCloseModal}
                    >
                      <span className="flex items-center justify-center text-lg">
                        {okButtonText}
                      </span>
                    </Button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default PopUpModal;
